import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Nuggets</title>
          <meta
            name="description"
            content="Pick up a pack of Raised & Rooted™ Chicken Nuggets for a delicious, meatless snack. Made with pea protein, these crispy nuggets are packed with nutrition."
          />
          <meta itemProp="name" content="Plant Based Nuggets" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Plant Based Nuggets"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Nuggets: Who doesn’t love nuggets? Raised & Rooted&trade; Nuggets are made with cravingly crispy batter and plant based protein for a great tasting meal or snack. Nuggets found in the frozen aisle."
              itemColor="#006B2D"
              valueText1="8"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="4"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="33"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made with Plant Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data For White Meat Nuggets"
              footnoteColor="#006B2D"
              productID="CHICKENNUGGET1"
            >
              <img src="/raised-and-rooted-plant-based-nuggets-708x721.png" alt="Raised And Rooted Plant Based Nuggets" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-plant-based-nuggets-708x721-ls.png"
              altText="Raised And Rooted Plant Based Nuggets"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Raised & Rooted™ Nuggets are plant based perfection made with a blend of pea protein isolate and golden flaxseed."
              slideReviewID="plant-based-nuggets"
              initialSlide={2}
            >
              <h4>NUTRITION</h4>
              <p>
                8 grams of protein. 4 grams of fiber per serving. And 33% less
                saturated fat than a regular USDA white meat chicken nugget.
                That’s the power of plants.
              </p>
              <h4>TASTE</h4>
              <p>
                What does all that nutrition add up to? Delicious nuggets!
                Crispy on the outside and tender on the inside. Because we
                believe what’s better for you can taste better too.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>HEATING INSTRUCTIONS</b>
                  <p>
                    Keep frozen until ready to use. Appliance Vary. Heating
                    times are approximate.
                  </p>
                  <b>Conventional OVEN</b>
                  <p>
                    Preheat oven to 400°F. Arrange nuggets on a baking sheet
                    about 1-inch apart. Bake for 10-12 minutes or until hot.
                  </p>
                  <b>MICROWAVE</b>
                  <p>
                    Arrange 4 nuggets on a microwave-safe plate. Microwave on
                    high for 55-65 seconds or until hot. Let stand for 1 minute,
                    before serving.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>2.5 Servings Per Container</p>
                  <p>Serving Size 4 pieces (90g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 200</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 10g</td>
                      <td>13%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 6g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 2.5g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 560mg</td>
                      <td>24%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 18g</td>
                      <td>7%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 4g</td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 0g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 8g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0.3mcg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Calcium 150mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Iron 1.8mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 140mg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *Percent daily values are based on a 2,000 calorie diet.
                        Your daily values may be higher or lower depending on
                        your calorie needs.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                  SEASONED PLANT PROTEIN PORTION: VEGAN FIBERS (WATER, PEA
                  PROTEIN ISOLATE, CANOLA OIL, SODIUM ALGINATE, CITRUS FIBER,
                  CALCIUM CHLORIDE), WATER, OAT FIBER, VITAL WHEAT GLUTEN, PEA
                  PROTEIN CONCENTRATE, SEASONING [MALTODEXTRIN (FROM CORN),
                  SALT, NATURAL FLAVOR, YEAST EXTRACT, PALMOIL, CARROTFIBER,
                  CITRICACID, MEDIUM CHAIN TRIGLYCERIDES, ONION POWDER, TORULA
                  YEAST, ONION JUICE CONCENTRATE, SPICE, CANOLAOIL], FLAXSEED,
                  METHYLCELLULOSE, SALT, NATURAL FLAVORING (SUNFLOWER OIL AND
                  ROSEMARY EXTRACT). BREADED WITH: WATER, WHEAT FLOUR, YELLOW
                  CORNFLOUR, FOOD STARCH, SALT, LEAVENING (SODIUM ACID
                  PYROPHOSPHATE, SODIUM BICARBONATE), SPICES, NATURAL FLAVOR.
                  BREADING SET IN VEGETABLE OIL (INCLUDING SOY BEAN OIL).
                </p>
                <H4 headingText="Allergens" />
                <ul className="allergens">
                  <li>Contains: wheat</li>
                </ul>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="CHICKENNUGGET1" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
